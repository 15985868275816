<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">IMEI Checker</h2>
        </b-link>

        <h2>Register</h2>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="$refs.recaptcha.execute()"
          >
            <!-- name -->
            <b-form-group label="Name" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="form.name"
                  :state="errors.length > 0 ? false : null"
                  name="register-name"
                  placeholder="john doe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- username -->
            <b-form-group label="Username" label-for="username">
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required|min:4"
              >
                <b-form-input
                  id="name"
                  v-model="form.username"
                  :state="errors.length > 0 ? false : null"
                  name="register-username"
                  placeholder="johndoe123"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Country -->
            <b-form-group label="Country" label-for="country">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <v-select
                    class="country-list"
                    v-model="form.country"
                    label="title"
                    :options="countries"
                    placeholder="Select country"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="form.email"
                  :state="errors.length > 0 ? false : null"
                  name="register email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <Password v-model="form.password" />
            <Password
              name="Confirm Password"
              label="Confirm Password"
              rules="required|confirmed:Password"
              v-model="cpassword"
            />

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                I agree to
                <b-link href="/terms">privacy policy & terms</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <div class="text-center">
              <Alert v-model="error" />
              <ReCaptcha ref="recaptcha" :submit="submit" />
              <b-button
                v-if="!loading"
                :disabled="!status"
                variant="primary"
                type="submit"
                block
              >
                Sign up
              </b-button>
              <b-spinner
                v-else
                variant="primary"
                type="grow"
                label="Spinning"
              />
            </div>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{ name: 'login' }">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import Auth from "@/services/auth.service.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Password from "@/components/util/Password.vue";
import ReCaptcha from "@/components/util/ReCaptcha.vue";
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BAlert,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import vSelect from "vue-select";
import { required, email as vEmail, min } from "@validations";
import countries from "@/libs/countries.js";
import Alert from "@/components/util/Alert.vue";

export default {
  components: {
    ReCaptcha,
    VuexyLogo,
    vSelect,
    Password,
    Alert,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BAlert,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: false,
    form: {
      name: "",
      username: "",
      email: "",
      password: "",
      country: "",
    },
    error: null,
    cpassword: "",
    status: "",
    countries,
    // validation rules
    required,
    vEmail,
    min,
  }),
  methods: {
    submit(token) {
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          try {
            await Auth.register({ ...this.form, recaptcha: token });
            setTimeout(() => {
              this.$bvToast.toast("Account created successfully", {
                title: "Login now!",
                variant: "success",
                solid: true,
              });
            }, 1000);
            this.$router.push({ name: "login" });
          } catch (e) {
            this.error = Object.values(
              e.response?.data?.errors || {
                err: ["Something went wrong please, try again later.."],
              }
            )[0][0];
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.country-list {
  width: 100%;
}
</style>
